const config = {
    "auth": {
      "aws_cognito_region": "us-west-2",
      "aws_user_pools_id": "us-west-2_mbPpYQpnG",
      "aws_user_pools_web_client_id": "5stnrdqms8909g1eck8a69vqdh",
      "aws_cognito_login_mechanisms": [
        "EMAIL"
      ],
      "aws_cognito_signup_attributes": [
        "EMAIL"
      ],
      "aws_cognito_username_attributes": [
        "EMAIL"
      ],
      "aws_cognito_mfa_configuration": "ON",
      "aws_cognito_mfa_types": [
        "SMS"
      ],
      "aws_mandatory_sign_in": "enable",
    },
    "env": "dev",
    "api_url": "https://8zbcuv4wzj.execute-api.us-west-2.amazonaws.com/dev"
}

export default config;
